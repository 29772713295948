import React from 'react';
import { withTranslation } from 'react-i18next';
import { globalConstants } from '../constants';

const FooterComponent = ({ t }) => (
	<div className="footer-component">
		<div>{t('label.your_member_number_will_not_be_shared')}</div>
		<div>{t('label.one_time_pass_are_used')}</div>
		<div>
			{t('label.you_will_never_be_asked')} {t('label.for_more_information_visit')}
		</div>
		<a
			target="_blank"
			aria-label={`${t('label.please_visit_cdr_pccu')} ${globalConstants.CONSUMER_DATA_RIGHT_POLICY}`}
			href={globalConstants.CONSUMER_DATA_RIGHT_POLICY}
			rel="noopener noreferrer">
			Consumer Data Right Policy (peopleschoice.com.au)
		</a>
		<a
			target="_blank"
			aria-label={`${t('label.please_visit_heritage_page')} ${globalConstants.HERITAGE_LINK}`}
			href={globalConstants.HERITAGE_LINK}
			rel="noopener noreferrer">
			Consumer Data Right Policy (heritagebank.com.au)
		</a>
	</div>
);

export default withTranslation()(FooterComponent);
